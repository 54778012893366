<template>
  <div>
    <loader v-show='loading'></loader>
    <div v-show='!loading'>
      <iframe :src='url' frameborder='0' v-if='url.length > 0' @load='onMyFrameLoad()'></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paymentZk',
  props: {
    dealObj: {
      type: Object,
      default() {
          return {
            DlCode: '00',
            CurPay: 0,
            ClientPhone: '',
            ClientFIO: '',
            Is_pdl: 0
          }
        },
    },
  },
  data() {
    return {
      descInfo: '',
      loading: true,
      zkInfo: {
        account: '', //Счёт/номер карты/номер договора/номер телефона и тп
        payee: '',//ФИО получателя
        amount: '500',//сумма платежа
        date: '',//дата в формате yyyy-mm-dd
        phone: '',//номер телефона плательщика
        flowType: 'MINIMALISTIC'//тип флоу. Возможные значения DEFAULT, CONFIRM_ONLY, SILENT, MINIMALISTIC
      },
      url: '',
    }
  },
  components: {
    loader: () => import('@/components/other/loader'),
  },
  watch: {
    'dealObj.CurPay': {
      handler() {
        this.createUrl()
      }
    }
  },
  methods: {
    onMyFrameLoad() {
      this.loading = false;
    },


    returnPdlStatus() {
      return new Promise((resolve, reject) => {
        this.$myApi.singleRequest({
          action: 'returnPdlStatus',
          type: 'pdl',
          demand: parseInt(this.dealObj.DlCode, 10)
        })
          .then(res => { console.log(res); resolve(res) })
          .catch(res => { console.log(res); reject() })
      });
    },
    createUrl() {
      let url = 'https://koronapay.com/repayment/online/finmoll?prefill=';
      let userObj = {
        "account":this.dealObj.DlCode      !== undefined ? this.dealObj.DlCode : '',
        "payee":  this.dealObj.ClientFIO   !== undefined ? this.dealObj.ClientFIO : '',
        "amount": this.dealObj.CurPay,
        "phone":  this.dealObj.ClientPhone !== undefined ? this.dealObj.ClientPhone: ''
      }
      this.url =  encodeURI(url + JSON.stringify(userObj));
    },
    loadingDAtaLostil() {
      if (this.dealObj.DlCode != undefined) {
        this.createUrl();
      } else {
        setTimeout(() => {
          this.loadingDAtaLostil();
        }, 500)
      }
    }
  },
  mounted() {
    if (this.dealObj.DlCode != undefined) {
      this.createUrl();
    } else {
      this.$store.dispatch('updateContentData').then(() => {
        this.loadingDAtaLostil();
      })
    }
  }
}
</script>

<style scoped>
iframe{width: 100%;height: 900px}
</style>
